<template>
  <div class="earnest-page">
    <Head title="保证金管理" />
    <Warpper top="40">
      <div class="earnest-head">
        <div class="earnest-ps">
          <h5>保证金最低需要缴纳100元</h5>
          <p>如无违约情况，保证金可随时申请退还；如违约，则扣除订单的15%作为违约金，剩余款项可退回，保证金不足将无法参与竞拍。</p>
        </div>
      </div>
      <div class="earnest-money">
        <p>保证金余额</p>
        <h3>¥{{money}}</h3>
      </div>
      <div class="earnest-status" v-if="state==0">
        <button @click="toRecharge">充值保证金</button>
      </div>
      <div class="earnest-status" v-if="state==1">
        <button disabled>充值成功</button>
        <p>已缴纳保证金，可放心参拍</p>
        <p>如需退还保证金，请联系客服微信号lakelandbook1</p>
      </div>
    </Warpper>
  </div>
</template>

<script>
import { reqGetToBuy, reqGetUserInfo } from "@/network/api";
export default {
  name: "earnest",
  data() {
    return {
      state: -1,
      money: 0,
      userInfo: this.$store.getters.userInfo
    };
  },
  created() {
    if (this.userInfo) {
      this.money = this.userInfo.price;
    } else {
      this.$router.push({ name: "login" });
    }
    this.state = this.$route.query.state || 0;

    reqGetUserInfo({
      user_id: 0
    }).then(async res => {
      const { msg, backdata, code } = res;
      if (code != 0) return;
      await this.$store.dispatch("user/setUserInfo", backdata.userinfo);
      this.userInfo = this.$store.getters.userInfo;
      if (+this.money < 100 && this.userInfo.mark == 0) {
        this.state = 0;
      } else {
        this.state = 1;
      }
    });
  },
  mounted() {},
  methods: {
    toRecharge() {
      reqGetToBuy({
        action: "pay_bzj"
      }).then(res => {
        const { code, msg, backdata } = res;
        if (code != 0) return this.$toast({ message: msg });
        this.$router.replace({
          name: "pay",
          query: {
            order: backdata.order,
            type: "pay_bzj"
          }
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.earnest-page {
  width: 100%;
  background-color: #fff;
}
.earnest-status {
  width: 6.3rem;
  margin: 0 auto;
  button {
    width: 100%;
    height: 0.88rem;
    background: linear-gradient(0deg, #328f96 0%, #69c4b8 100%);
    border-radius: 44px;
    border: 0;
    font-size: 0.34rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    margin-bottom: 0.44rem;
    color: #ffffff;
    &:focus {
      outline: none;
    }
    &:disabled {
      background: linear-gradient(0deg, #a2a2a2 0%, #e7e7e7 100%);
    }
  }
  p {
    font-size: 0.24rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-bottom: 0.19rem;
    text-align: center;
  }
}
.earnest-money {
  width: 6.3rem;
  margin: 0.8rem auto 0.97rem;
  p {
    font-size: 0.26rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-bottom: 0.42rem;
  }
  h3 {
    font-size: 0.8rem;
    font-family: MS Reference Sans Serif;
    font-weight: 400;
    color: #333333;
  }
}
.earnest-head {
  width: 100%;
  height: 3rem;
  background: #eff0ef;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  .earnest-ps {
    width: 6.9rem;
    height: 2.56rem;
    background: linear-gradient(-35deg, #d9b788 0%, #e8d1a8 100%);
    border-radius: 0.2rem 0.2rem 0 0;
    box-sizing: border-box;
    padding: 0.44rem 0.3rem 0;
    h5 {
      font-size: 0.26rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-bottom: 0.33rem;
      line-height: 0.36rem;
      &::before {
        content: "!";
        border-radius: 50%;
        width: 0.28rem;
        height: 0.28rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 0.02rem solid #080808;
        margin-right: 0.14rem;
      }
    }
    p {
      font-size: 0.24rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #7f4e11;
      line-height: 0.36rem;
    }
  }
}
</style>